<template>
    <div class="pay-auto fadeInRight">
        <div class='pay-header'>
            <p class='info'>
                <i class="el-icon-present" style='font-size:20px'></i>
                <span style='font-size:20px;margin-left:15px'>充值中心</span>
            </p>
            <p class='info'>1.当前界面是预充值款自助充值</p>
            <p class='info'>2.充值余额不可提现，只可用于卡充值套餐</p>
        </div>
        <div class='pay-container'>
            <div class='container-top'>
                <div style='float:left;height:30px;line-height:30px'>
                    <i class="el-icon-present" style='font-size:15px'></i>
                    <span style='font-size:15px'>充值账号:{{nickName}}</span>
                </div>
                <div style='float:right;height:30px;line-height:30px'>
                    <i class="el-icon-present" style='font-size:15px'></i>
                    <span style='font-size:15px'>剩余金额:{{leftMoney}}</span>
                </div>
            </div>

            <div class='container-main'>
                <el-form ref="form" :model="payForm" label-width="100px">
                <el-form-item label="充值金额">
                    <el-radio-group v-model="inputMoney" @change="changeMoney">
                        <el-radio border :label="''+ 100">100￥</el-radio>
                        <el-radio border :label="''+ 200">200￥</el-radio>
                        <el-radio border :label="''+ 500">500￥</el-radio>
                        <el-radio border :label="''+ 1000">1000￥</el-radio>
                        <el-radio border :label="''">其他金额</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="输入金额" v-show='showInput'>
                    <el-input v-model="payForm.money" style='width:200px'></el-input>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-radio-group v-model="payForm.type">
                        <el-radio border :label="1">微信支付</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="应付金额">
                    <el-input v-model="payForm.money" readonly style='width:200px'></el-input>
                </el-form-item>
               
                <el-form-item size="large">
                    <el-button type="primary" @click="onSubmit">确认支付</el-button>
                    <span>温馨提示，扫码完成后请点击支付完成</span>
                </el-form-item>
                </el-form>
            </div>
        </div>

        <el-dialog
            title="微信支付扫码"
            :visible.sync="payURLDialogVisible"
            @close="closeCode"
            width="30%"
            center>
            <div class="paycode" style="width:150px;height:150px;margin:auto">
                <div id="qrcode" ref="qrcode" style="text-align:center"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="surePay">已支付</el-button>
                <el-button @click="payURLDialogVisible = false">取消支付</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from "qrcodejs2"
import {getAgentType,getPayURL,alreadyPay} from "@/api/agentManagement.js"
import {agentstatistics} from "@/api/welcome.js"
export default {
    data(){
        return {
            inputMoney:'',
            payForm:{
                money:'',
                type:1,
            },
            showInput:true,
            loginAgentType:'',
            nickName:'',
            leftMoney:'',
            payURL:'',
            payURLDialogVisible:false,
            payUrl:'',
            orderNumber:'',
        }
    },
    created() {
        this.getAgentType()
        // this.getLeftMoney()
    },

    methods: {
        crateQrcode () {
            let qr = new QRCode('qrcode', {
            width: 150,
            height: 150, // 高度
            text: this.payUrl // 二维码内容
            })
        },
        getAgentType(){
				getAgentType().then(res=>{
                    console.log(res)
					this.loginAgentType = res.agentType
					this.nickName = res.nickName
                    this.leftMoney  = res.balanceMoney
				})
            },
        // getLeftMoney(){
        //     agentstatistics({
		// 			version: 1
		// 		}).then(res => {
		// 			this.leftMoney = res.agentStatisMainEntity.preRechargeBalance
		// 		})
        // },
        changeMoney(val){
            this.payForm.money = val
            if(val==''){
                this.showInput = true
            }else{
                this.showInput = false
            }
        },
        onSubmit(){
            if(!this.payForm.money){
                this.$alert('请选择充值金额', '提示', {
					confirmButtonText: '确定',
				});
				return
            }
            if(parseFloat(this.payForm.money) < 50){
                this.$alert('充值金额不小于50元', '提示', {
					confirmButtonText: '确定',
				});
				return
            }
            getPayURL(this.payForm).then(res=>{
                this.payURLDialogVisible = true

                this.payUrl = res.url
                this.orderNumber = res.orderNumber
                this.$nextTick(() => {
                    this.crateQrcode()
                })
            })
        },

        surePay(){
            if(!this.orderNumber){
                this.$alert('获取订单失败', '提示', {
					confirmButtonText: '确定',
				});
				return
            }
            alreadyPay({orderNumber:this.orderNumber}).then(res=>{
                if(res.status == '1'){
                    this.$message({
							showClose: true,
							message: '已支付',
							type: 'success',
                    })
                    // this.getLeftMoney()
                    this.getAgentType()
                }else if(res.status == '2'){
                    this.$message({
							showClose: true,
							message: '未支付',
							type: 'success',
					})
                }
            })
            this.payURLDialogVisible = false
        },

        closeCode(){
            this.payURLDialogVisible = false
            this.$refs.qrcode.innerHTML = ''
        }

    },

}
</script>

<style>
    .pay-header{
        padding:10px 20px;
        background: #27b0d6;
        color: #fff;
    }
    .pay-header p{
        padding:10px 0;
    }
    .pay-container{
        border:1px solid skyblue;
        padding:30px
    }

    .container-top{
        padding:20px;
        background:rgba(39, 176, 214, .15);
        height:30px;
        margin-bottom: 40px;
    }
</style>